import AppBar from "@mui/material/AppBar";
import { styled } from '@mui/material/styles';
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";
import { Link as RouterLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import MenuIcon from '@mui/icons-material/Menu';
import { Button } from "@mui/material";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    minHeight: 12,
  },
}));

const NavBar = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <StyledToolbar>
            <Typography sx={{ flexGrow: 1 }}>
                <Button component={RouterLink} to="/" color="inherit" variant="outlined" sx={{ mr: 1 }}>Hjem</Button>
                <Button component={RouterLink} to="/worklist" color="inherit" variant="outlined" sx={{ mr: 1 }}>Arbeidsliste</Button>
            </Typography>
            <WelcomeName />
            <SignInSignOutButton />
        </StyledToolbar>
      </AppBar>
    </Box>
     
    );
};

export default NavBar;