
export async function callUnisoftTimebokAPI(accessToken, endpoint) {

    const headers = new Headers();
    const bearer = 'Bearer '+accessToken;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch("https://bht.timebok.unisoft.no/api/"+endpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function callUnisoftTimebokAPIwithBody(accessToken, endpoint, body, method="POST") {

    const headers = new Headers();
    const bearer = 'Bearer '+accessToken;

    headers.append("Authorization", bearer);

    const options = {
        method: method,
        headers: headers,
        body: JSON.stringify(body)
    };

    return fetch("https://bht.timebok.unisoft.no/api/"+endpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}