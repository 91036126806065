import * as React from 'react';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem } from '@mui/material';

export const BookSelector = ({label, books, onChange}) => {

    const handleChange = (event) => {
        setValue(event.target.value);
        onChange(event.target.value);
    };

    const [value, setValue] = React.useState('1');

    return (
   <div>
     <InputLabel>
     {label}
     </InputLabel>
      <Select value={value} onChange={handleChange}>
      {books != null ? books.map(function(object, i){
        return <MenuItem key={object.id} value={object.id}>{object.name}</MenuItem>
      }) : <MenuItem>None</MenuItem>}
      </Select>
   </div>
 );

};