import { Component } from "react";

// Msal imports
import { MsalAuthenticationTemplate, withMsal } from "@azure/msal-react";
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

// Sample app imports
import { ProfileData } from "../ui-components/ProfileData";
import { Loading } from "../ui-components/Loading";
import { ErrorComponent } from "../ui-components/ErrorComponent";
import { callMsGraph } from "../utils/MsGraphApiCall";

// Material-ui imports
import Paper from "@mui/material/Paper";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';

import { callUnisoftTimebokAPI, callUnisoftTimebokAPIwithBody } from "../utils/UnisoftTimebokAPI";
import { TimebokData } from "../ui-components/TimebokData";
import { BookSelector } from "../ui-components/BookSelector";
import { FormControl } from "@mui/material";

/**
 * This class is a child component of "Profile". MsalContext is passed
 * down from the parent and available as a prop here.
 */
class WorklistContent extends Component {

    constructor(props) {
        super(props)

        this.state = {
          //  graphData: null,
            timebokData: null,
            booksData: null, /*[
            {name: "VGS", value: 1},
            {name: "BHT", value: 2},
            {name: "Skien Fylkeshus", value: 7},
        ],*/
            selectedDate: dayjs(),
            selectedBook: 1
        }
    }

    /*
    setGraphData() {
        if (!this.state.graphData && this.props.msalContext.inProgress === InteractionStatus.None) {
            callMsGraph().then(response => this.setState({graphData: response})).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    this.props.msalContext.instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: this.props.msalContext.instance.getActiveAccount()
                    });
                }
            });
        }
    }
    */
    getBookData() {
        if (this.props.msalContext.inProgress === InteractionStatus.None) {
            const accessTokenRequest = {
                scopes: ["d20da621-9eb4-4c67-9772-6b0598e800ec/.default"],
                account: this.props.msalContext.instance.getActiveAccount(),
            };

            this.props.msalContext.instance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse =>
            {
                    let accessToken = accessTokenResponse.accessToken;
                    callUnisoftTimebokAPI(accessToken, "books/").then(
                        response => this.setState({booksData: response.Books})
                    ).catch((e) => {
                        if (e instanceof InteractionRequiredAuthError) {
                            this.props.msalContext.instance.acquireTokenRedirect({
                                ...loginRequest,
                                account: this.props.msalContext.instance.getActiveAccount()
                            });
                        }
                    });
                }   
            )
        }
    }

    setTimebokData(book, date) {

        //if (!this.state.timebokData && this.props.msalContext.inProgress === InteractionStatus.None) {
        if (this.props.msalContext.inProgress === InteractionStatus.None) {
            const accessTokenRequest = {
                scopes: ["d20da621-9eb4-4c67-9772-6b0598e800ec/.default"],
                account: this.props.msalContext.instance.getActiveAccount(),
            };

            this.props.msalContext.instance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse =>
            {
                    let accessToken = accessTokenResponse.accessToken;
                    callUnisoftTimebokAPI(accessToken, "patients/" + book + "/" + date.format('YYYY-MM-DD') + "/").then(
                        response => this.setState({timebokData: response})
                    ).catch((e) => {
                        if (e instanceof InteractionRequiredAuthError) {
                            this.props.msalContext.instance.acquireTokenRedirect({
                                ...loginRequest,
                                account: this.props.msalContext.instance.getActiveAccount()
                            });
                        }
                    });
                }   
            )
        }
    }

    setTimeslotStatus = (timeslot, status) => {
        if (this.props.msalContext.inProgress === InteractionStatus.None) {
            const accessTokenRequest = {
                scopes: ["d20da621-9eb4-4c67-9772-6b0598e800ec/.default"],
                account: this.props.msalContext.instance.getActiveAccount(),
            };

            if (this.state.timebokData && this.state.timebokData.timeslot) {
                let timebokData = this.state.timebokData;
                for (let i = 0; i < timebokData.timeslot.length; i++) {
                    if (timebokData.timeslot[i].id == timeslot) {
                        timebokData.timeslot[i].status = status;
                        this.setState({timebokData: timebokData})
                        break;
                    }
                }
            }

            this.props.msalContext.instance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse =>
            {
                    let accessToken = accessTokenResponse.accessToken;
                    //alert(JSON.stringify({'id': timeslot, 'status': status}, null, 4))
                    callUnisoftTimebokAPIwithBody(accessToken, "timeslot", {'id': timeslot, 'status': status}, "PUT").then(
                        response => {
                            //alert(JSON.stringify(response))
                            this.setTimebokData(this.state.selectedBook, this.state.selectedDate);
                        }
                    ).catch((e) => {
                        if (e instanceof InteractionRequiredAuthError) {
                            this.props.msalContext.instance.acquireTokenRedirect({
                                ...loginRequest,
                                account: this.props.msalContext.instance.getActiveAccount()
                            });
                        }
                    });
                }   
            )
        }
    }

    componentDidMount() {
        this.getBookData();
        this.setTimebokData(this.state.selectedBook, this.state.selectedDate);
    }

    componentDidUpdate(prevProps, prevState) {
        //this.setGraphData();
        if (this.state.booksData == null) {
            this.getBookData();
        }
        if (this.state.timebokData == null || prevState.selectedDate != this.state.selectedDate || prevState.selectedBook != this.state.selectedBook) {
            this.setTimebokData(this.state.selectedBook, this.state.selectedDate);
        }
    }

    setSelectedDate = (newValue) => {
        this.setState({selectedDate: newValue, timebokData: null});
    }
    setSelectedBook = (newValue) => {
        this.setState({selectedBook: newValue, timebokData: null});
    }
  
    render() {
 
        return (
            <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker label="Velg dato" onChange={this.setSelectedDate} defaultValue={this.state.selectedDate}/>
                </LocalizationProvider>
                <BookSelector books={this.state.booksData} label="Timebok" onChange={this.setSelectedBook} />
                <hr/>
                <br/>
                {/* this.state.graphData ? <ProfileData graphData={this.state.graphData} /> : null */}
                <TimebokData timebokData={this.state.timebokData} onNewTimeslotStatus={this.setTimeslotStatus}/>
            </FormControl>
        );
    }
}

/**
 * This class is using "withMsal" HOC and has access to authentication
 * state. It passes down the msalContext as a prop to its children.
 */
class Worklist extends Component {


    render() {
        
        const authRequest = {
            ...loginRequest
        };
        
        return (
            <MsalAuthenticationTemplate 
                interactionType={InteractionType.Redirect} 
                authenticationRequest={authRequest} 
                errorComponent={ErrorComponent} 
                loadingComponent={Loading}
            >
                <WorklistContent msalContext={this.props.msalContext}/>
            </MsalAuthenticationTemplate>
        );
    }
}

// Wrap your class component to access authentication state as props
export const WorklistWithMsal = withMsal(Worklist);