import { Button } from "@mui/material";

const OpenEpjButton = ({ person, text }) => {
    const handleButtonClick = () => {
        const openEpjLink = 'uniargs:epj?' + person.first_name + ' '+ person.last_name + '&' + person.identifier;
        window.location.href = openEpjLink;
    }

    return <Button onClick={handleButtonClick}>{ text }</Button>;
    
}

export default OpenEpjButton;