import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon  from "@mui/icons-material/Work";
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { DataGrid, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Box, Button } from "@mui/material";
import OpenEpjButton from "./OpenEpjButton";


const columns = [
  { field: 'start', headerName: 'Tidspunkt', width: 250 },
  { field: 'name', headerName: 'Navn', width: 250 },
  { field: 'status', headerName: 'Vaksinert', width: 250 },
  { field: 'action', headerName: 'Handlinger', width: 350, renderCell: (params) => {

    const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking

        if (params.value && params.value.timeslot.status) {
          params.value.onNewTimeslotStatus(params.value.timeslot.id, null);
        } else {
          params.value.onNewTimeslotStatus(params.value.timeslot.id, new Date().toISOString());
        }
        //return alert(JSON.stringify(params.value.timeslot.person, null, 4));
      };

      let button =  (params.value && params.value.timeslot.status) ?  <Button onClick={onClick}>Fjern registrering</Button> : <Button onClick={onClick}>Registrer som vaksinert</Button>;
      
      return params.value ? <div><OpenEpjButton person={ params.value.timeslot.person } text={ "Åpne i EPJ" } />{ button }</div> : <div></div>;
  } },
];

function TimebokDataRows(timebokData, onNewTimeslotStatus) {
    const rows = [];
    let i = 0;
    if (timebokData && timebokData.timeslot) {
      for (i = 0; i < timebokData.timeslot.length; i++) {
        rows[i] = timebokData.timeslot[i]
        rows[i].name = timebokData.timeslot[i].person.first_name + " " + timebokData.timeslot[i].person.last_name
        rows[i].status = timebokData.timeslot[i].status
        rows[i].action = {timeslot: timebokData.timeslot[i], onNewTimeslotStatus: onNewTimeslotStatus}
      }
    } else {
      rows[0] = {'id': 0, 'start': null, 'name': 'Laster...', 'action': null}
    }

    return rows;
}

export const TimebokData = ({timebokData, onNewTimeslotStatus}) => {
    return (
        <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={TimebokDataRows(timebokData, onNewTimeslotStatus)}
        columns={columns}
        localeText={{
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} av ${count}`,
            labelRowsPerPage: 'Antall rader pr. side:'
            },
          noRowsLabel: 'Ingen rader på valgt dato',
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        slots={{toolbar: GridToolbarQuickFilter,}}
        pageSizeOptions={[25, 50, 100]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
    );
};